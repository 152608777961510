import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useQueryParam, BooleanParam } from "use-query-params";
import {
  Typography,
  Tooltip,
  Card,
  CardHeader,
  CardMedia,
  Divider,
  CardContent,
  CardActions,
  IconButton,
  Avatar,
  Grid
} from "@material-ui/core";
import AvatarGroup from "@material-ui/lab/AvatarGroup";

import FavoriteIcon from "@material-ui/icons/Favorite";
import PlayIcon from "@material-ui/icons/PlayCircleFilled";
import {
  get,
  map,
  split,
  xor,
  includes,
  filter,
  drop,
  take,
  isEmpty
} from "lodash";

import { formatDistanceToNow } from "date-fns";
import { he } from "date-fns/locale";
import firebase from "firebase/app";
import "firebase/auth";
import { useCurrentUser, action } from "../hooks";
import { text } from "../static-text";
import SimplePhotosCarousel from "../SimplePhotosCarousel";
import { SHOW_LOGIN_PARAM } from "../LoginDialog";
import { usePlayerDispatch, playerAction } from "../player/context";
import AdminMenu from "./AdminMenu";

export default function PostCard({ post, lightMode }) {
  const classes = useStyles();
  const [currentUser] = useCurrentUser();

  const userId = !isEmpty(currentUser) && currentUser.uid;

  const [showLogin = false, setShowLogin] = useQueryParam(
    SHOW_LOGIN_PARAM,
    BooleanParam
  );

  const dispatchPlayer = usePlayerDispatch();
  const db = firebase.firestore();
  const toggleLike = async () => {
    if (!userId) {
      return setShowLogin(true);
    }

    await db
      .collection("posts")
      .doc(post.id)
      .update({
        likeIds: xor(post.likeIds, [userId])
      });
  };

  const onDelete = async () => {
    await db
      .collection("posts")
      .doc(post.id)
      .delete();
  };

  const { videoLink, videoThumb } = post || {};

  return (
    <Card className={clsx(classes.card, lightMode && classes.lightMode)}>
      <CardHeader
        avatar={
          <Avatar
            aria-label="recipe"
            className={classes.avatar}
            src={get(post, "author.photoURL")}
          >
            R
          </Avatar>
        }
        title={get(post, "author.displayName")}
        subheader={displayCreatedAt(post)}
        action={
          get(currentUser, "isAdmin") && <AdminMenu onDelete={onDelete} />
        }
      />
      <div className={clsx(lightMode && classes.contentLightMode)}>
        <SimplePhotosCarousel
          imageIds={get(post, "imageIds")}
          lightMode={lightMode}
        />

        <CardContent>
          {videoLink && (
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
              style={{ height: "100%" }}
            >
              {!isEmpty(post.videoTitle) && (
                <Typography
                  variant="subtitle2"
                  style={{
                    padding: 16,
                    fontSize: 12,
                    fontWeight: 700,
                    textAlign: "center"
                  }}
                >
                  {post.videoTitle}
                </Typography>
              )}
              <CardMedia
                image={videoThumb}
                className={clsx(classes.player, !videoThumb && classes.noThumb)}
              >
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justify="center"
                  style={{ height: "100%" }}
                >
                  <IconButton
                    aria-label="play video"
                    onClick={() => {
                      dispatchPlayer(
                        action(playerAction.setUrl, { url: videoLink })
                      );
                      dispatchPlayer(action(playerAction.show));
                    }}
                  >
                    <PlayIcon style={{ color: "white", fontSize: 80 }} />
                  </IconButton>
                </Grid>
              </CardMedia>
            </Grid>
          )}
          {videoLink && !isEmpty(get(post, "text")) && (
            <Divider className={classes.divider} />
          )}
          {map(split(get(post, "text"), "\n"), line => (
            <Typography variant="body1" color="textSecondary" component="p">
              {line}
            </Typography>
          ))}
        </CardContent>
      </div>

      <CardActions disableSpacing>
        <IconButton
          aria-label="add to favorites"
          onClick={() => toggleLike()}
          color={includes(get(post, "likeIds"), userId) ? "primary" : "default"}
        >
          <FavoriteIcon />
        </IconButton>
        <OtherUserLikes likes={get(post, "likes")} currentUserId={userId} />
      </CardActions>
    </Card>
  );
}

const OtherUserLikes = ({ likes, currentUserId }) => {
  const likesWithoutMe = isEmpty(currentUserId)
    ? likes
    : filter(likes, ({ userId }) => userId !== currentUserId);

  if (isEmpty(likesWithoutMe)) {
    return null;
  }

  return (
    <AvatarGroup>
      {map(take(likesWithoutMe, 3), like => (
        // <Badge
        //   overlap="circle"
        //   anchorOrigin={{
        //     vertical: "bottom",
        //     horizontal: "right"
        //   }}
        //   badgeContent={
        //     <FavoriteIcon style={{ fontSize: 14 }} color="primary" />
        //   }
        // >
        <Tooltip title={like.displayName}>
          <Avatar alt={like.displayName} src={like.photoURL} />
        </Tooltip>
        // </Badge>
      ))}
      {likesWithoutMe.length > 3 && (
        <Tooltip title={map(drop(likesWithoutMe, 3), like => like.displayName)}>
          <Avatar>+{likesWithoutMe.length - 3}</Avatar>
        </Tooltip>
      )}
    </AvatarGroup>
  );
};

const useStyles = makeStyles(theme => ({
  card: {
    width: "100%",
    direction: "rtl",
    flip: false,
    marginLeft: 1
  },
  lightMode: {
    minHeight: 600
  },
  contentLightMode: {
    height: 500,
    overflowY: "auto"
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  player: {
    maxWidth: 320,
    width: "100%",
    height: 240
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  noThumb: {
    maxWidth: 320,
    background: theme.palette.secondary.main,
    borderRadius: 60,
    width: 120,
    height: 120
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main
  }
}));

const displayCreatedAt = post => {
  if (!post || !post.createdAt || !post.createdAt.seconds) {
    return "";
  }

  const createdAt = new Date(post.createdAt.seconds * 1000);

  return `${text.feed.publishedAt} ${formatDistanceToNow(createdAt, {
    locale: he
  })}`;
};
