import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink
} from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import GoogleFontLoader from "react-google-font-loader";
import {
  createMuiTheme,
  StylesProvider,
  jssPreset
} from "@material-ui/core/styles";
import { create } from "jss";
import rtl from "jss-rtl";
import { QueryParamProvider } from "use-query-params";
import ScrollToTop from "react-router-scroll-top";

import Navigation from "./Navigation";
import LoginPage from "./Login";
import Home from "./Home";
import Feed from "./Feed";
import Songs from "./Songs";
import LoginDialog from "./LoginDialog";
import PrivacyPolicy from "./PrivacyPolicy";
import UserSpeedDial from "./UserSpeedDial";
import { text } from "./static-text";
import Album from "./Album";
import { PlayerProvider } from "./player/context";
import SnackbarPlayer from "./player";
import Footer from "./Footer";

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

function RTL(props) {
  return <StylesProvider jss={jss}>{props.children}</StylesProvider>;
}

const theme = createMuiTheme({
  direction: "rtl",
  palette: {
    primary: {
      light: "#78acb3",
      main: "#809799",
      contrastText: "#fff"
    },
    secondary: {
      light: "#bfa48d",
      main: "#bfa48d",
      dark:  "#bfa48d",
      contrastText: "#fff"
    },
    background: {
      paper: "#f0f0f0"
    }

    // error: will use the default color
  },
  typography: {
    fontFamily: [
      '"Assistant"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    h1: {
      fontSize: 33,
      fontWeight: 800
    },
    h2: {
      fontWeight: 800
    },
    h3: {
      fontSize: 32,
      fontWeight: 800
    }
  }
});

const Link = props => (
  <NavLink
    {...props}
    activeStyle={{
      color: theme.palette.secondary.light
    }}
  />
);

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RTL>
        <GoogleFontLoader
          fonts={[
            {
              font: "Assistant",
              weights: [400, 800]
            }
          ]}
          subsets={["hebrew"]}
        />
        <PlayerProvider>
          <Router>
            <ScrollToTop>
              <QueryParamProvider ReactRouterRoute={Route}>
                <LoginDialog />
                <div
                  style={{ minHeight: "100vh", background: "#fff" }}
                  dir="rtl"
                >
                  <UserSpeedDial />
                  <Navigation>
                    <Link exact to="/">
                      {text.nav.home}
                    </Link>
                    <Link to="/feed">{text.nav.feed}</Link>
                    <Link to="/songs">{text.nav.songs}</Link>
                    <Link to="/album">{text.nav.album}</Link>
                  </Navigation>
                  <Switch>
                    <Route exact path="/">
                      <Home />
                    </Route>
                    <Route path="/feed">
                      <Switch>
                        <Route exact path="/feed">
                          <Feed />
                        </Route>
                      </Switch>
                    </Route>
                    <Route path="/songs">
                      <Switch>
                        <Route exact path="/songs">
                          <Songs />
                        </Route>
                      </Switch>
                    </Route>
                    <Route path="/album">
                      <Switch>
                        <Route exact path="/album">
                          <Album />
                        </Route>
                      </Switch>
                    </Route>
                    <Route exact path="/privacy-policy">
                      <PrivacyPolicy />
                    </Route>
                    <Route path="/login">
                      <LoginPage />
                    </Route>
                  </Switch>
                  <Footer />
                  <SnackbarPlayer />
                </div>
              </QueryParamProvider>
            </ScrollToTop>
          </Router>
        </PlayerProvider>
      </RTL>
    </ThemeProvider>
  );
}
