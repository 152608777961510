import React from "react";
import {
  IconButton,
  Button,
  MenuItem,
  Menu,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import MoreIcon from "@material-ui/icons/MoreVert";

export default function AdminMenu({ onDelete = console.log }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleDeleteAndCloseDialog = () => {
    handleCloseDialog();
    onDelete();
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActionClose = action => () => {
    handleClose();
    action();
  };

  return (
    <div>
      <IconButton
        aria-controls="admin-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreIcon />
      </IconButton>
      <Menu
        id="admin-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleActionClose(handleClickOpen)}>
          מחק פוסט לתמיד
        </MenuItem>
      </Menu>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">מחיקת פוסט</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            לא יהיה ניתן לשחזר את הפוסט לעולם האם למחוק בכל זאת?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            ביטול
          </Button>
          <Button
            onClick={handleDeleteAndCloseDialog}
            color="primary"
            autoFocus
          >
            אישור מחיקה
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
