// src/count-context.js
import React from "react";

const PlayerStateContext = React.createContext();
const PlayerDispatchContext = React.createContext();

const playerAction = {
  playPause: "p",
  stop: "s",
  show: "d",
  hide: "h",
  setUrl: "u"
};

function playerReducer(state, action) {
  switch (action.type) {
    case playerAction.playPause: {
      return { ...state, playing: !state.playing };
    }
    case playerAction.stop: {
      return { ...state, playing: false };
    }
    case playerAction.show: {
      return { ...state, show: true, playing: false };
    }
    case playerAction.hide: {
      return { ...state, show: false, playing: false };
    }
    case playerAction.setUrl: {
      return {
        ...state,
        url: action.url
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
function PlayerProvider({ children }) {
  const [state, dispatch] = React.useReducer(playerReducer, {
    show: false,
    playing: false,
    url: null
  });
  return (
    <PlayerStateContext.Provider value={state}>
      <PlayerDispatchContext.Provider value={dispatch}>
        {children}
      </PlayerDispatchContext.Provider>
    </PlayerStateContext.Provider>
  );
}

function usePlayerState() {
  const context = React.useContext(PlayerStateContext);
  if (context === undefined) {
    throw new Error("usePlayerState must be used within a PlayerProvider");
  }
  return context;
}
function usePlayerDispatch() {
  const context = React.useContext(PlayerDispatchContext);
  if (context === undefined) {
    throw new Error("usePlayerDispatch must be used within a PlayerProvider");
  }
  return context;
}
export { PlayerProvider, usePlayerState, usePlayerDispatch, playerAction };
