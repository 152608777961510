import React from "react";
import {
  Paper,
  Container,
  Grid,
  Typography,
  Hidden,
  Fab,
  makeStyles,
  useMediaQuery,
  Button
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import HeroImage from "./HeroImage";
import IntroSection from "./IntroSection";
import MediaCarousel from "./MediaCarousel";
import PhotosCarousel from "./PhotosCarousel";
import PostCarousel from "./PostCarousel";
import { usePlayerDispatch, playerAction } from "./player/context";
import { action } from "./hooks";
import { text } from "./static-text";
import { Link } from "react-router-dom";

import mobileBackgroundImage from "./images/Michal-Hero-Mobile.jpg";
import backgroundImage from "./images/MichalHeader.jpg";

export default function Home() {
  const classes = useStyles();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const dispatchPlayer = usePlayerDispatch();

  return (
    <React.Fragment>
      <Grid container>
        <HeroImage src={smUp ? backgroundImage : mobileBackgroundImage} />
        <Container>
          <Grid container spacing={3} className={classes.section}>
            <Grid item xs={0} sm={2} md={1}></Grid>
            <Grid item xs={12} sm={2} md={2} container justify="flex-end" />
            <Grid item xs={12} sm={8} md={8}>
              <IntroSection />
            </Grid>
            <Grid item xs={12} sm={2} md={3}></Grid>
          </Grid>
        </Container>
      </Grid>
      <SectionHeader
        title={text.home.feedTitle}
        moreLinkTo="/feed"
        moreLinkLabel={text.home.morePosts}
        className={clsx(classes.sectionHeader, classes.feed)}
      />
      <div className={clsx(classes.carouselWrapper, classes.feed)}>
        <PostCarousel />
      </div>
      <SectionHeader
        title={text.home.songsTitle}
        moreLinkTo="/songs"
        moreLinkLabel={text.home.moreSongs}
        className={clsx(classes.blue, classes.sectionHeader)}
      />

      <div className={clsx(classes.blue, classes.carouselWrapper)}>
        <MediaCarousel
          onSelected={(e, mediaDetails) => {
            dispatchPlayer(
              action(playerAction.setUrl, { url: mediaDetails.url })
            );
            dispatchPlayer(action(playerAction.show));
          }}
        />
      </div>
      <SectionHeader
        title={text.home.albumTitle}
        moreLinkTo="/album"
        moreLinkLabel={text.home.morePhotos}
        className={clsx(classes.sectionHeader)}
      />
      <div className={clsx(classes.carouselWrapper)}>
        <PhotosCarousel />
      </div>
    </React.Fragment>
  );
}

const SectionHeader = ({ title, moreLinkTo, moreLinkLabel, className }) => {
  const classes = useStyles();

  return (
    <Container className={className} maxWidth={false}>
      <Grid
        container
        spacing={3}
        className={classes.section}
        alignItems="center"
      >
        <Grid item xs sm={3} md={2} lg={3}></Grid>
        <Grid item xs={7} sm={6} md={8} lg={6} justify="flex-start">
          <Typography
            component="h3"
            variant="h3"
            className={classes.sectionTitle}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item xs={4} sm={2} md={2} justify="flex-end">
          <Hidden smDown>
            <Button
              size="large"
              component={Link}
              to={moreLinkTo}
              aria-label={text.more}
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<ArrowBackIcon className={classes.linkIcon} />}
            >
              {moreLinkLabel}
            </Button>
          </Hidden>
          <Hidden mdUp>
            <Fab
              component={Link}
              to={moreLinkTo}
              aria-label={text.more}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              <ArrowBackIcon />
            </Fab>
          </Hidden>
        </Grid>
      </Grid>
    </Container>
  );
};

const useStyles = makeStyles(theme => ({
  section: {
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(6)
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(2)
    },
    paddingTop: theme.spacing(8)
  },
  button: {
    direction: "rtl",
    margin: theme.spacing(1),
    padding: "8px 16px",
    [theme.breakpoints.down("sm")]: {
      direction: "ltr"
    }
  },
  linkIcon: {
    marginLeft: theme.spacing(1)
  },
  feed: {
    background: "#efe8dc"
  },
  blue: {
    background: theme.palette.secondary.dark,
    color: "white"
  },
  sectionHeader: {
    marginTop: theme.spacing(8),
    paddingBottom: theme.spacing(4)
  },
  carouselWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(6)
  },
  sectionTitle: {
    marginBottom: theme.spacing(1)
  }
}));
