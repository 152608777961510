import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { makeStyles, GridList, GridListTile } from "@material-ui/core";
import { noop, map, flatMap, isEmpty, compact, isEqual, chunk } from "lodash";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { usePosts } from "./hooks";
import firebase from "firebase/app";
import "firebase/storage";

let storage = null;

export default function PhotosCarousel({ onSelected = noop }) {
  const classes = useStyles();
  const posts = usePosts({ limit: 20, filter: "photos" });

  const imageIds = compact(flatMap(posts, "imageIds"));

  if (!storage) {
    storage = firebase.storage();
  }

  const [images, setImages] = useState([]);

  useEffect(() => {
    Promise.all(
      map(imageIds, async imageId => {
        try {
          const thumbRef = storage.ref(`/images/thumbs/${imageId}_400x400`);
          const thumbnail = await thumbRef.getDownloadURL();

          return { thumbnail };
        } catch (e) {
          console.log(e);
        }
      })
    ).then(updatedImages => {
      const cleanUpdatedImages = compact(updatedImages);
      !isEmpty(cleanUpdatedImages) &&
        !isEqual(images, cleanUpdatedImages) &&
        setImages(cleanUpdatedImages);
    });
  }, [imageIds, images]);

  return (
    <div className={classes.root}>
      <Slider {...settings}>
        {map(chunk(images, 1), (imageChunk, index) => (
          <GridList
            key={index}
            cellHeight={240}
            className={classes.gridList}
            cols={1}
          >
            {map(imageChunk, (image, index) => (
              <GridListTile key={index} cols={1}>
                <img src={image.thumbnail} alt="" />
              </GridListTile>
            ))}
          </GridList>
        ))}
      </Slider>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    direction: "ltr",
    pointerEvents: "none"
  },
  gridList: {
    height: 500,
    width: 100
  }
}));

const settings = {
  dots: false,
  infinite: true,
  speed: 8010,
  slidesToShow: 5,
  slidesToScroll: 1,
  initialSlide: 0,
  autoplaySpeed: 8000,
  autoplay: true,
  arrows: false,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
  ]
};
