import React from "react";
import {
  Paper,
  Container,
  Grid,
  Typography,
  Hidden,
  Box,
  makeStyles,
  useMediaQuery,
  Button
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { usePlayerDispatch, playerAction } from "./player/context";
import { action } from "./hooks";
import { text } from "./static-text";
import { Link } from "react-router-dom";
import mobileBackgroundImage from "./images/Michal-Hero-Mobile.jpg";
import backgroundImage from "./images/MichalHeader.jpg";

import HeroImage from "./HeroImage";
import MediaCarousel from "./MediaCarousel";
import PostCardNew from "./PostCardNew";

export default function Songs() {
  const classes = useStyles();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const dispatchPlayer = usePlayerDispatch();

  return (
    <React.Fragment>
      <Grid container>
        <HeroImage src={smUp ? backgroundImage : mobileBackgroundImage} />
      </Grid>
      <Container
        className={clsx(classes.blue, classes.songsHeader)}
        maxWidth={false}
      >
        <Grid container spacing={3} className={classes.section}>
          <Grid item xs sm={3} md={2}></Grid>
          <Grid item xs={8} sm={3} md={8} justify="flex-start">
            <Typography component="h3" variant="h3">
              {text.songs.allSongsTitle}
            </Typography>
          </Grid>
          <Grid item xs={3} md={2} justify="flex-end"></Grid>
        </Grid>
      </Container>
      <div className={clsx(classes.blue, classes.carouselWrapper)}>
        <MediaCarousel
          limit={100}
          onSelected={(e, mediaDetails) => {
            dispatchPlayer(
              action(playerAction.setUrl, { url: mediaDetails.url })
            );
            dispatchPlayer(action(playerAction.show));
          }}
        />
      </div>
      <Container
        className={clsx(classes.blue, classes.songsInput)}
        maxWidth={false}
      >
        <Grid
          container
          spacing={3}
          className={classes.section}
          justify="center"
        >
          <PostCardNew />
        </Grid>
      </Container>
    </React.Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  section: {
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(6)
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(2)
    },
    paddingTop: theme.spacing(8)
  },
  button: {
    direction: "ltr",
    margin: theme.spacing(1)
  },
  blue: {
    background: theme.palette.secondary.dark,
    color: "white"
  },
  songsInput: {
    paddingBottom: theme.spacing(8)
  },
  songsHeader: {
    paddingBottom: theme.spacing(6)
  },
  carouselWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(8)
  }
}));
