import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  Card,
  Grid,
  CardMedia,
  CardContent,
  CardActions,
  Collapse,
  TextField,
  IconButton,
  Button,
  Typography,
  CircularProgress
} from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import YouTubeIcon from "@material-ui/icons/YouTube";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import CloseIcon from "@material-ui/icons/Close";
import BackspaceIcon from "@material-ui/icons/Backspace";
import InputAdornment from "@material-ui/core/InputAdornment";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SendIcon from "@material-ui/icons/Send";
import PlayIcon from "@material-ui/icons/PlayArrow";
import { text } from "./static-text";
import firebase from "firebase/app";
import { useQueryParam, BooleanParam } from "use-query-params";
import { map, dropRight, isEmpty, compact, includes, concat } from "lodash";
import getThumb from "video-thumbnail-url";
import urlParser from "js-video-url-parser";
import { ImageUpload } from "./ImageUpload";
import { SHOW_LOGIN_PARAM } from "./LoginDialog";
import { useDebounce, action } from "./hooks";
import getYoutubeTitleCb from "get-youtube-title";
import { usePlayerDispatch, playerAction } from "./player/context";

const getYoutubeTitle = (videoId = "ZjM8Wq5pQ2o") =>
  new Promise((resolve, reject) => {
    getYoutubeTitleCb(videoId, function(err, title) {
      if (err) {
        reject(err);
      } else {
        resolve(title);
      }
    });
  });

const expand = {
  youtube: "yt",
  image: "img"
};

const videoWebSitesLinks = [
  { label: "YouTube", url: "https://www.youtube.com" },
  { label: "Vimeo", url: "https://www.vimeo.com" },
  { label: "SoundCloud", url: "https://www.soundcloud.com" }
];

const supportedVideoProviders = ["soundcloud", "youtube", "vimeo"];
const SoundCloud = "sc";

export default function PostCardNew() {
  const db = firebase.firestore();
  const classes = useStyles();

  const [postText, setPostText] = useState(null);
  const [postVideoLink, setPostVideoLink] = useState(null);
  const [postVideoThumbnail, setPostVideoThumbnail] = useState(null);
  const [loadingVideoThumb, setLoadingVideoThumb] = useState(false);
  const [postVideoTitle, setPostVideoTitle] = useState("");
  const [sending, setSending] = useState();
  const [expanded, setExpanded] = useState(false);
  const [images, setImages] = useState([]);
  const [linkError, setLinkError] = useState(false);

  const debouncedPostLink = useDebounce(postVideoLink, 500);
  console.log({ images });
  useEffect(() => {
    (async function fetchVideoThumb() {
      setLoadingVideoThumb(true);
      const videoLinkData =
        !isEmpty(debouncedPostLink) &&
        debouncedPostLink.trim() &&
        urlParser.parse(debouncedPostLink);

      if (
        !videoLinkData ||
        !includes(supportedVideoProviders, videoLinkData.provider)
      ) {
        setPostVideoThumbnail(null);
        setLoadingVideoThumb(false);
      } else {
        if (videoLinkData.provider === "soundcloud") {
          setPostVideoThumbnail(SoundCloud);
          setLoadingVideoThumb(false);
        } else {
          if (videoLinkData.provider === "youtube") {
            try {
              const title = await getYoutubeTitle(videoLinkData.id);
              setPostVideoTitle(title);
            } catch (e) {
              console.log(`failed to load info for youtube video url`, e);
              setPostVideoTitle(null);
            }
          }
          try {
            const thumb = await getThumb(debouncedPostLink);
            setPostVideoThumbnail(thumb);
          } catch (e) {
            console.log(`failed to load thumb for video url`, e);
            setPostVideoThumbnail(null);
          }

          setLoadingVideoThumb(false);
        }
      }
    })();
  }, [debouncedPostLink]);
  const isValidVideoLink = postVideoLink && urlParser.parse(postVideoLink);

  const dispatchPlayer = usePlayerDispatch();

  const [showLogin = false, setShowLogin] = useQueryParam(
    SHOW_LOGIN_PARAM,
    BooleanParam
  );

  const handleImageAdded = imageId => {
    setImages(imageIds => concat(imageIds, imageId));
  };

  const removeLastImage = imageId => {
    setImages(imageIds => dropRight(imageIds));
  };

  const isValidPost =
    !isEmpty(
      compact([
        !isEmpty(postText && postText.trim()),
        !isEmpty(images),
        !isEmpty(isValidVideoLink)
      ])
    ) &&
    (!postVideoLink || (isValidVideoLink && !loadingVideoThumb));

  const handleSend = async () => {
    if (!firebase.auth().currentUser) {
      return setShowLogin(true);
    }
    setSending(true);

    try {
      await db.collection("posts").add({
        text: postText && postText.trim(),
        videoLink: postVideoLink,
        videoLinkCreationDate: isEmpty(postVideoLink)
          ? null
          : firebase.firestore.FieldValue.serverTimestamp(),
        videoThumb: postVideoThumbnail,
        videoTitle: postVideoTitle,
        imageIds: images,
        imagesCreationDate: isEmpty(images)
          ? null
          : firebase.firestore.FieldValue.serverTimestamp(),
        numberOfImages: isEmpty(images) ? 0 : images.length,
        userRef: db.doc("users/" + firebase.auth().currentUser.uid),
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      });

      setPostText("");
      setPostVideoLink("");
      setExpanded(false);
      setImages([]);
      setLoadingVideoThumb(false);
      setPostVideoThumbnail(null);
      setPostVideoTitle(null);
    } catch (error) {
      console.log(error);
    }
    setSending(false);
  };

  const handleClose = () => setExpanded(false);
  const setExpandedOrDisplayLogin = expandKey => () => {
    if (!firebase.auth().currentUser) {
      return setShowLogin(true);
    }
    setExpanded(expandKey);
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <TextField
          label={text.feed.newPostTextPlaceholder}
          multiline
          variant="outlined"
          className={classes.textInput}
          value={postText}
          onChange={e => setPostText(e.target.value)}
        />
      </CardContent>

      <CardActions className={classes.slimActions}>
        <IconButton
          aria-label="add photo"
          color="primary"
          onClick={setExpandedOrDisplayLogin(expand.image)}
        >
          <AddAPhotoIcon />
        </IconButton>

        <IconButton
          aria-label="add a song from youtube"
          color="primary"
          onClick={setExpandedOrDisplayLogin(expand.youtube)}
        >
          <YouTubeIcon />
        </IconButton>
      </CardActions>

      <CollapseWithTitleAndCloseButton
        when={expanded === expand.youtube || !isEmpty(postVideoLink)}
        title={text.feed.shareVideoTitle}
        onClose={handleClose}
        validInput={isValidVideoLink}
      >
        <Typography variant="subtitle2" component="overline">
          {text.feed.shareVideoSubtitlePrefix}
          {map(videoWebSitesLinks, (link, index) => (
            <React.Fragment>
              <a
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.videoUrlLink}
              >
                {link.label}
              </a>
              {index < videoWebSitesLinks.length - 1 ? ", " : ""}
            </React.Fragment>
          ))}
        </Typography>
        <TextField
          label={text.feed.shareVideoURLPlaceHolder}
          variant="filled"
          className={classes.textInput}
          value={postVideoLink}
          color="secondary"
          error={linkError}
          onChange={e => {
            setPostVideoLink(e.target.value);
            !e.target.value && setLinkError(false);
          }}
          onBlur={() => {
            if (isEmpty(postVideoLink)) {
              setLinkError(false);
            } else {
              setLinkError(!isValidVideoLink);
            }
          }}
          InputProps={{
            endAdornment: (
              <React.Fragment>
                <InputAdornment position="end">
                  <IconButton
                    aria-label="clear"
                    disabled={isEmpty(postVideoLink)}
                    onClick={() => setPostVideoLink("")}
                  >
                    <BackspaceIcon style={{ transform: "rotateY(180Deg)" }} />
                  </IconButton>
                </InputAdornment>
                <IconButton
                  aria-label="play video preview"
                  disabled={!isValidVideoLink}
                  onClick={() => {
                    dispatchPlayer(
                      action(playerAction.setUrl, { url: debouncedPostLink })
                    );
                    dispatchPlayer(action(playerAction.show));
                  }}
                >
                  <PlayIcon />
                </IconButton>
              </React.Fragment>
            )
          }}
        />
      </CollapseWithTitleAndCloseButton>
      <CollapseWithTitleAndCloseButton
        when={expanded === expand.image || !isEmpty(images)}
        title={text.feed.sharePhotosTitle}
        onClose={handleClose}
        validInput={!isEmpty(images)}
      >
        <ImageUpload
          onRequestSave={id => handleImageAdded(id)}
          onRequestClear={removeLastImage}
          defaultFiles={
            images
              ? map(images, imageId => ({
                  source: imageId,
                  options: {
                    type: "local"
                  }
                }))
              : []
          }
        />
      </CollapseWithTitleAndCloseButton>

      <CardActions disableSpacing className={classes.leftAlignedActions}>
        <div dir="rtl">
          <Button
            disabled={!isValidPost}
            aria-label="send"
            color="primary"
            variant="contained"
            endIcon={
              sending ? (
                <CircularProgress size={16} />
              ) : (
                <SendIcon className={classes.flipIconLeft} />
              )
            }
            onClick={handleSend}
          >
            {text.feed.send}
          </Button>
        </div>
      </CardActions>
    </Card>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    width: 580,
    maxWidth: "80vw"
  },
  textInput: {
    width: "100%"
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },

  avatar: {
    backgroundColor: red[500]
  },
  slimActions: {
    paddingLeft: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: 0
  },
  leftAlignedActions: {
    justifyContent: "flex-end"
  },
  flipIconLeft: {
    transform: "rotateY(180Deg)"
  },
  videoUrlLink: {
    color: theme.palette.secondary.light
  }
}));

const CollapseWithTitleAndCloseButton = ({
  when,
  title,
  children,
  onClose,
  validInput
}) => (
  <Collapse in={when} timeout="auto" unmountOnExit>
    <CardContent>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          {validInput ? (
            <CheckCircleIcon color="primary" />
          ) : (
            <IconButton
              aria-label="close"
              color="primary"
              onClick={onClose}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          )}
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">{title}</Typography>
        </Grid>
      </Grid>

      {children}
    </CardContent>
  </Collapse>
);
