import firebase from "firebase/app"


const isProduction = process.env.NODE_ENV === "production";

// Configure Firebase.
const firebaseProdConfig = {
  apiKey: "AIzaSyAY3IomfIq4yq1NgFEufig2s93u4O2QEYw",
  authDomain: "mich-22551.firebaseapp.com",
  databaseURL: "https://mich-22551.firebaseio.com",
  projectId: "mich-22551",
  storageBucket: "mich-22551.appspot.com",
  messagingSenderId: "548051452455",
  appId: "1:548051452455:web:8debef746d749e85af8402",
  measurementId: "G-71NWERMX8X"
};
// Initialize Firebase
const firebaseDevConfig = {
  apiKey: "AIzaSyAIWHJhKcYjhUnUk50h-SVKWrR3KGrAMy8",
  authDomain: "mich-dev.firebaseapp.com",
  databaseURL: "https://mich-dev.firebaseio.com",
  projectId: "mich-dev",
  storageBucket: "mich-dev.appspot.com",
  messagingSenderId: "1001843567882",
  appId: "1:1001843567882:web:83b365ec1ee63a2bec1ef3"
};

const firebaseConfig = isProduction || true ? firebaseProdConfig : firebaseDevConfig;

export default function() {
  firebase.initializeApp(firebaseConfig);
  isProduction && firebase.analytics();
}
