import React from "react";

export default function PrivacyPolicy() {
  return (
    <div style={{ direction: "rtl", padding: "10%" }}>
      <h1>מדיניות פרטיות</h1>
      <p>
        מדיניות פרטיות האתר כל הדברים הטובים להלן: “האתר” “אנחנו” או “אנו ) מכבד
        את זכותך לפרטיות. ברצוננו לאפשר לך לקבל החלטות מושכלות לגבי השימוש במידע
        שלך. מטרת מדיניות הפרטיות היא להסביר כיצד אנו נוהגים ביחס למידע הנאסף
        באמצעות האתר וכיצד אנחנו אוספים, משתמשים, מוסרים ומגנים על מידע שמקבל
        מהמשתמשים. מדיניות הפרטיות מנוסחת בלשון זכר לצרכי נוחות בלבד, והיא
        מתייחסת לנשים וגברים כאחד. המונחים במסמך זה, אשר לא הוגדרו במפורש
        במסגרתו, יפורשו בהתאם להגדרות שניתנו להם בתנאי השימוש. מידע שאנו אוספים
        “מידע אישי” או “מידע” הינו מידע שאפשר לשייכו אל אדם או ישות, כגון שם,
        מספר טלפון או כתובת אימייל. לא חלה עליך חובה חוקית למסור את המידע,
        ומסירת המידע תלויה ברצונך ובהסכמתך, אך אם לא תסכים למסור את המידע, כולו
        או חלקו, ייתכן וחלק מן השירותים שאנו מספקים יימנע ממך. כמו כן, אנחנו
        עשויים לשמור פרטים אחרים אודות שימושך בשירותים, כגון פרסומות שקראת באתר,
        העמודים בהם צפית או ההצעות והשירותים שעניינו אותך. בנוסף, אנו עשויים
        לתעד את התקשורת שלנו אתך כדי לסייע בפתרון בעיות שאתה או משתמשים אחרים
        עלולים להיתקל בהן. ישנו מידע מסוים שאפשר ונאסף בצורה אוטומטית אודות
        המחשב, הדפדפן, מערכת ההפעלה, כתובת ה-IP ו/או מכשיר הנייד שלך. כיצד אנו
        מגנים על המידע אנו נוקטים באמצעים המקובלים בתעשייה על מנת להגן על המידע
        שנמסר לנו, גם בזמן המסירה וגם לאחר שאנו מקבלים אותו. אך, לא קיימות שיטות
        העברה או אחסון אלקטרוני שהינן בטוחות לחלוטין. לכן, למרות שאנו משתדלים
        לנקוט בדרכים מקובלות בכדי להגן על המידע שלך, איננו יכולים להבטיח את
        ביטחונו המוחלט של המידע. אנחנו נמחק כל מידע אישי שקיבלנו ממשתמש לאחר
        קבלת דרישה בכתב מאותו משתמש. איננו יכולים לשחזר מידע שנמחק. כיצד אנו
        משתמשים במידע לא נשתף את המידע שלך עם גורמים אחרים ללא הסכמתך המפורשת,
        פרט לדרכים שיוזכרו כאן במפורש, ואלא אם כן נידרש לעשות כן לפי כל חוק,
        תקנה או צו בית משפט או בכדי לשתף פעולה עם חקירה של רשויות אכיפת החוק.
        אנחנו משתמשים במידע האישי שמסרת על מנת לשלוח לך דיוור אלקטרוני. אנחנו
        עשויים להשתמש במידע לצרכים הפנימיים שלנו, כגון על מנת לאבחן בעיות עם
        השרתים שלנו או כדי להפוך את השירותים שלנו ליותר שימושיים או להתאימם
        לצרכים שלך. אנחנו עשויים להעביר את המידע שלך ל: חברות הקשורות אלינו, או
        לספקים ו/או קבלני משנה, כגון ספקים שאוספים, מעבדים ו/או מאחסנים מידע
        עבורנו. אנו נדרוש מהחברות הקשורות אלנו, מהספקים ו/או מקבלני המשנה לפעול
        על פי נוהלינו להגנה על הפרטיות. שותפים עסקיים איתם אנו עשויים להציע
        במשותף מוצרים או שירותים, או שמוצריהם או שירותיהם יוצעו באתר שלנו. תוכל
        לדעת מתי צד שלישי קשור בהצעה של מוצר או שירות כששמו יופיע בהצעה, לבד או
        יחד עם שמנו. אם תבחר להשתמש במוצרים או בשירותים אלה, אנו עשויים לשתף
        מידע אודותיך, כולל המידע האישי שלך, עם אותם השותפים. שים לב שאיננו
        שולטים בנוהלי הפרטיות של השותפים העסקיים הללו. אם אינך מעוניין עוד לאפשר
        לצד שלישי להשתמש במידע שלך, צור קשר איתנו. הפניה לאתרי אינטרנט אחרים. אם
        הופנית לאתר זה מאתר אחר (לדוגמה, באמצעות קישור), אנו עשויים לשתף מידע
        מסוים אודותיך עם האתר המפנה. אנו מעודדים אותך לעיין במדיניות הפרטיות של
        כל אתר שהפנה אותך לאתר שלנו. בנוסף, כאשר אתה עובר לאתר של השותפים שלנו
        דרך קישור מהאתר שלנו, אנו מספקים לשותף זה מזהה ייחודי, כך שהוא ידע
        שהופנית אליו מהאתר שלנו. מזהה ייחודי זה לא יכיל את המידע האישי שלך.
        בנוסף, ייתכן שבאמצעות ניתוח של כל המידע שאנחנו נאסוף או נקבל, כולל מידע
        לגבי המשתמשים, אנו עשויים ליצור מידע סטטיסטי אודות מגוון רחב של משתמשים
        (להלן: “מידע סטטיסטי“). מידע סטטיסטי מסייע לנו בהבנת מגמות וצרכיהם של
        המשתמשים בכדי שנוכל לשקול שירותים ומוצרים חדשים ובכדי שנוכל להתאים
        שירותים ומוצרים קיימים לצרכיהם של המשתמשים. מידע סטטיסטי הינו אנונימי
        ולא נקשר אותו למידע אישי. ייתכן שנמסור מידע סטטיסטי לשותפים שלנו, ללא
        הגבלה, לפי תנאים מסחריים שנקבע לפי שיקול דעתנו הבלעדי. בנוסף, אנו
        משתמשים בכלי הנקרא “Google Analytics” על מנת לאסוף מידע אודות השימוש
        באתר ו/או השירותים. Google Analytics אוסף מידע כגון התדירות שבה משתמשים
        מבקרים באתר, אילו עמודים הם מבקרים כאשר הם עושים זאת ובאיזה אתרים הם
        השתמשו טרם הגעתם לאתר. Google Analytics אוסף רק את כתובת ה-IP שהוקצתה לך
        ביום ביקורך באתר, ואינו אוסף את שמך או מידע מזהה אחר. אנו לא משלבים בין
        המידע הנאסף באמצעות שימוש בGoogle Analytics לבין מידע המאפשר זיהוי אישי.
        אנו משתמשים במידע המתקבל מGoogle Analytics על לשפר את האתר והשירותים
        שלנו בלבד. היכולת של Google להשתמש במידע שנאסף על ידי Google Analytics
        ולשתף אותו בנוגע לביקורים באתר זה מוגבלת בהתאם לתנאי השימוש של Google
        Analytics, הנמצאים כאן ובהתאם למדיניות הפרטיות, אשר ניתן לצפות בה כאן.
        כמו כן אנחנו עושים שימוש בשירותים של צדדים שלישיים לצרכי אנליטיקה,
        פרסומות, קישורים ממומנים ותוכניות שותפים. קבצי Cookies Cookie הינו קטע
        קצר של טקסט הנשלח אל הדפדפן של המבקר באתר. בעת ביקור חוזר, הדפדפן שולח
        את קטע זה למכשירו של המבקר המקורי. אנחנו משתמשים בקבצי cookies כדי
        להתאים את חוויית השימוש שלך לצרכים שלך. ייתכן ונשתמש ב-cookie “מתמיד” על
        מנת לשמור את ההגדרות והתאמות האישיות שלך. כמו כן, אם תיכנס לאתר,
        ה-cookie ישמש כדי לזהות אותך כמשתמש תקף, כדי שלא תצטרך להכניס את פרטיך
        מחדש בכל שימוש באתר. רוב דפדפני האינטרנט מוגדרים מראש בצורה המאפשרת קבלת
        cookies, אבל אתה יכול לשנות את ההגדרה הזו כך שהדפדפן שלך יחסום את כל ה-
        cookiesאו כדי שהדפדפן יודיע לך כל פעם ש- cookieנשלח. כמו כן, אתה יכול
        למחוק כל cookie קיים בכל עת. אך ייתכן וחלק מהתכונות של השירותים ו/או של
        האתר לא יעבדו כראוי אם תבטל או תסיר את ה- cookies. קישורים ייתכן ואנחנו
        נציג קישורים לאתרי אינטרנט של צדדים שלישיים. כל מידע אישי שתמסור לכל צד
        שלישי הינו מידע הנמסר ישירות לצד שלישי והינו כפוף למדיניות הפרטיות של
        אותו צד שלישי. חוץ מכפי שמתואר לעיל, אנחנו לא נהיה אחראיים לתוכן או
        לנהלי פרטיות של צדדים שלישיים כאמור. קישורים מהאתר שלנו מוגשים אליך
        לנוחיותך. אנחנו ממליצים לך ללמוד על נהלי הפרטיות של כל גוף לפני שאתה
        מוסר לו מידע כלשהו, כולל מידע אישי. איננו יכולים להיות אחראים לשימוש של
        המידע שלך על ידי גופים שלישיים. שימושים אחרים והעברת מידע אנחנו נשתף
        פעולה עם רשויות אכיפת החוק או כל צו שיפוטי הדורש או מנחה אותנו לגלות את
        הזהות, ההתנהגות או התוכן הדיגיטלי של כל משתמש שישנו חשד שנקט בפעולות
        הנחזות למנוגדות לדין או בפעולות מפרות חוק. אנחנו עשויים להעביר את מאגרי
        המידע שלנו הכוללים את המידע שלך אם נמכור את העסק שלנו או חלק ממנו, לרבות
        העברה מחוץ לישראל. עיון, תיקון או עדכון של המידע שלך אם ברצונך לבקש
        מאיתנו לעיין, לתקן או לעדכן את המידע שלך או אם ברצונך לבקש שלא נשתמש
        במידע שלך כדי לספק לך שירותים, צור עמנו קשר דרך עמוד צור קשר באתר או דרך
        כתובת המייל noapaz0@gmail.com. תקשורת והודעות על ידי הצטרפות לרשימת
        התפוצה אתה מאשר לנו לשלוח לך הודעות באמצעות הדוא”ל אודות השירותים או
        המוצרים שלנו. תוכל להסיר את הפרטים האישיים שלך מרשימת התפוצה שלנו
        ולהפסיק לקבל מאתנו מסרים בעתיד, על ידי לחיצה על קישור ביטול המופיע בכל
        מסר או על ידי שליחת דוא”ל לכתובת noapaz0@gmail.com. שינויים במדיניות
        הפרטיות מדיניות הפרטיות שלנו עשויה להשתנות מעת לעת. במידה ויבוצעו
        שינויים במדיניות הפרטיות שעניינם צמצום זכויותיך או שימוש שונה במידע
        האישי שמסרת, תפורסם על כך הודעה באתר. יצירת קשר בכל שאלה או בעיה הקשורה
        למדיניות הפרטיות יש לפנות ל: noapaz0@gmail.com או דרך טופס יצירת קשר
        באתר. עדכון אחרון: דצמבר, 2019
      </p>
    </div>
  );
}
