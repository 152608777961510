import React from "react";

import { makeStyles, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flip: false,
    direction: "rtl",
    padding: theme.spacing(1),

    "& p": {
      flip: false,
      direction: "rtl",
      textAlign: "justify",
      lineHeight: 1.75,
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up("sm")]: {
      textAlign: "right",
    },
  },
  bigFirstWord: {
    fontSize: 72,
    lineHeight: "36px",
  },
  lastWord: {
    fontSize: 22,
    lineHeight: "12px",
  },
}));

export default function IntroSection() {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.root}>
      <Typography component="p">
        <strong className={classes.bigFirstWord}>מיכל </strong>
        שלנו נולדה בעתלית, והאירה את חיינו, ההורים, האחיות האחיינים, הסבים, וכל
        המשפחה הענפה באור גדול במשך 23 שנים. ואז אורה כבה, וחושך גדול החליף את
        האור הגדול.
      </Typography>
      <Typography component="p">
        בשנים שהיתה איתנו, זכינו אנחנו המשפחה וכל מי שהכיר או פגש אותה להתחמם
        מאורה.
        <br /> הכשרון המוזיקלי של מיכל ניכר בכל אחד מרגעי חייה, השמחים והאחרים.
        <br />
        כשמיכל שרה וקולה נשמע, אף עין לא נשארה יבשה.
        <br /> מיכל שרה, כתבה, ניגנה על מגוון כלי נגינה. המוזיקה ניגנה את חייה.
        <br />{" "}
      </Typography>
      <Typography component="p">
        מי שזכה לפגוש את מיכל, זכה שתראה את מנגינת ליבו, את הנימים הדקים
        והרגישים ביותר.
        <br /> מיכל ידעה לראות את טובו של האדם, את מצוקותיו, וברוב חמלה ידעה
        להכיל כמו ״מיכל״ את האחר, ולקבל אותו כפי שהוא.
        <br /> אלה היו מפגשי נפש עם נפש.
        <br /> מיכל תמיד נתנה עצה באהבה, הקשיבה לחוץ ולפנים האחר, ורצתה בכל
        מאודה לעזור לו להסיר את הקושי.
        <br /> מנגינת חייה נדמה, כשמאחוריה שובל קסום ונדיר של אהבת האדם
        <strong className={classes.lastWord}> ...</strong>
      </Typography>
    </div>
  );
}
