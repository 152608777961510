import React, { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { isEmpty, map, get } from "lodash";
import firebase from "firebase/app";
import "firebase/storage";
import "react-image-gallery/styles/css/image-gallery.css";

let storage = null;

export default function({ imageIds, lightMode }) {
  if (isEmpty(imageIds)) {
    return null;
  }
  if (!storage) {
    storage = firebase.storage();
  }
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const [images, setImages] = useState([]);

  useEffect(() => {
    (async () => {
      const updatedImages = await Promise.all(
        map(imageIds, async imageId => {
          const imageRef = storage.ref(`/images/${imageId}`);

          const original = await imageRef.getDownloadURL();
          try {
            const thumbRef = storage.ref(`/images/thumbs/${imageId}_400x400`);

            const thumbnail = await thumbRef.getDownloadURL();
            return { original, thumbnail };
          } catch (e) {
            console.log(e);
            return { original, thumbnail: original };
          }
        })
      );

      setImages(updatedImages);
    })();
  }, [imageIds]);

  const showNavigation = get(imageIds, "length", 0) > 1;
  return (
    <ImageGallery
      items={images}
      isRTL
      showThumbnails={showNavigation && smUp && !lightMode}
      showBullets={showNavigation && !smUp}
      showPlayButton={showNavigation && !lightMode}
      showFullscreenButton={smUp}
    />
  );
}
