import React, { useState, useEffect } from "react";
import { map, isEmpty } from "lodash";
import firebase from "firebase/app";

export const sort_option = {
  time_desc: { column: "createdAt", direction: "desc" }
};

const filter_option = {
  songs: "songs",
  photos: "photos"
};

// TODO optimize user Fetches.
export default function usePosts(props) {
  const [posts, setPosts] = useState([]);
  const { sortBy = "time_desc", limit = 200, filter } = props || {};

  useEffect(() => {
    const db = firebase.firestore();

    const getFilteredPosts = () => {
      switch (filter) {
        case filter_option.songs:
          return db
            .collection("posts")
            .orderBy("videoLinkCreationDate", "desc")
            .where("videoLinkCreationDate", ">", new Date(0));
        case filter_option.photos:
          return db
            .collection("posts")
            .orderBy("imagesCreationDate", "desc")
            .where("imagesCreationDate", ">", new Date(0));
        default:
          return db
            .collection("posts")
            .orderBy(sort_option[sortBy].column, sort_option[sortBy].direction);
      }
    };
    const unsubscribe = getFilteredPosts()
      .limit(limit)
      .onSnapshot(async snapshot => {
        // console.log(snapshot.size);
        const newPosts = await Promise.all(
          map(snapshot.docs, async doc => {
            const post = { id: doc.id, ...doc.data() };
            const likes = await Promise.all(
              map(post.likeIds, async likeUserId => {
                const userLike = await db
                  .collection("users")
                  .doc(likeUserId)
                  .get();
                const userLikeData = userLike.data();

                return { ...userLikeData, userId: likeUserId };
              })
            );

            if (post.userRef) {
              const user = await db
                .collection("users")
                .doc(post.userRef.id)
                .get();

              return {
                ...post,
                likes,
                author: user.data()
              };
            }
            return post;
          })
        );
        setPosts(newPosts);
      });
    return () => unsubscribe();
  }, [sortBy, filter, limit]);

  return posts;
}
