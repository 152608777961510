import React, { useState } from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  SwipeableDrawer,
  Hidden,
  Zoom,
  Fab,
  useScrollTrigger,
  Collapse
} from "@material-ui/core";
import MoreIcon from "@material-ui/icons/MoreHorizTwoTone";
import { map, castArray } from "lodash";
import { useCurrentUser } from "./hooks";

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),

    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    position: "fixed",
    zIndex: 2,

    [theme.breakpoints.down("xs")]: {
      top: theme.spacing(2),
      right: theme.spacing(2),
      marginLeft: 0,
      padding: 0,
      background: "transparent",
      color: theme.palette.primary.main,
      height: "auto"
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(0)
    },
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(0)
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "calc((100vw - 1280px) / 2 + 32px)"
    }
  },
  list: {
    [theme.breakpoints.down("xs")]: {
      width: 170
    },
    direction: "rtl"
  },
  drawer: {
    background: "rgba(0,0,0,0.2)"
  },
  drawerPaper: {
    padding: theme.spacing(6),
    background: theme.palette.primary.main,
    color: "#fff"
  }
}));

export default function Navigation({ children }) {
  const classes = useStyles();
  const [mobileMenuOpen, setMobileMenuOpen] = useState();
  const [show, setShow] = useState();
  const [currentUser, logout] = useCurrentUser();
  const trigger = useScrollTrigger({});

  function NavigationList({ afterClick }) {
    return (
      <List dense className={classes.list}>
        {map(
          castArray(children),
          (
            {
              props: { children: linkChildren, ...linkProps },
              type: LinkComponent
            },
            index
          ) => (
            <ListItem
              component={LinkComponent}
              button
              onClick={afterClick}
              key={index}
              {...linkProps}
            >
              <ListItemText
                style={{ textAlign: "right" }}
                primaryTypographyProps={{
                  style: { fontWeight: 800, fontSize: 18 }
                }}
                primary={linkChildren}
              />
            </ListItem>
          )
        )}
      </List>
    );
  }

  const showDesktopNavigation = !trigger || show;

  return (
    <Box className={classes.root}>
      <Hidden smUp implementation="js">
        <Zoom in={!trigger}>
          <Fab
            color="primary"
            aria-label="Open Menu"
            onClick={() => setMobileMenuOpen(true)}
          >
            <MoreIcon />
          </Fab>
        </Zoom>
        <SwipeableDrawer
          anchor="right"
          className={classes.drawer}
          open={mobileMenuOpen}
          onClose={() => setMobileMenuOpen(false)}
          onOpen={() => setMobileMenuOpen(true)}
          PaperProps={{ className: classes.drawerPaper }}
        >
          <Grid container>
            <Box padding={2}>
              <Typography component="h1" variant="h1">
                מיכל
              </Typography>
            </Box>
          </Grid>
          <NavigationList afterClick={() => setMobileMenuOpen(false)} />
        </SwipeableDrawer>
      </Hidden>
      <Hidden xsDown implementation="js">
        <Grid
          onMouseEnter={() => setShow(true)}
          onMouseLeave={() => setShow(false)}
        >
          <Grid container>
            <Box
              paddingLeft={2}
              paddingRight={2}
              paddingTop={3}
              paddingBottom={2}
            >
              <Typography component="h1" variant="h1">
                מיכל
              </Typography>
            </Box>
          </Grid>
          <Collapse in={showDesktopNavigation} timeout="auto">
            <NavigationList />
          </Collapse>
        </Grid>
      </Hidden>
    </Box>
  );
}
