import React from "react";
// import Slider from "react-slick";
import "slick-carousel";
import $ from "jquery";
import { makeStyles, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import PostCard from "./PostCard";
import { map, noop } from "lodash";
import { usePosts } from "./hooks";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

export default function MediaCarousel({ onSelected = noop }) {
  const classes = useStyles();
  const posts = usePosts({ limit: 10 });

  React.useEffect(() => {
    if (posts.length > 2) {
      $("#post-crousel").slick(settings);
    }
  })
  return (
    <div className={classes.root}>
      <div id="post-crousel">
        {map(posts, post => (
          <div className={classes.cardWrapper}>
            <PostCard
              key={post.id}
              post={post}
              onClick={onSelected}
              lightMode
            />
          </div>
        ))}
      </div>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    }
  },
  cardWrapper: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 2
    },
    paddingLeft: theme.spacing(2)
  }
}));

const settings = {
  rtl: true,
  dots: true,
  infinite: true,
  speed: 1200,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,

  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};
