import React from "react";
import {
  Button,
  Card,
  Snackbar,
  Grid,
  IconButton,
  LinearProgress
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PauseIcon from "@material-ui/icons/Pause";
import { makeStyles } from "@material-ui/core/styles";
import ReactPlayer from "react-player";
import { action } from "../hooks";

import { usePlayerDispatch, usePlayerState, playerAction } from "./context";

const useStyles = makeStyles(theme => ({
  root: {
    direction: "ltr",
    width: "100%"
  },
  blue: {
    background: theme.palette.secondary.main,
    color: "white"
  }
}));

const playerheightPlaying = 60;
const playerWidthPlaying = 180;
const playerHeightStopped = 160;
const playerWidthStopped = 280;

export default function SnackbarPlayer(props) {
  const [progress, setProgress] = React.useState(0);
  const { show, url, playing } = usePlayerState();
  const dispatch = usePlayerDispatch();

  const classes = useStyles();

  const handleClick = () => {
    dispatch(action(playerAction.show));
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(action(playerAction.hide));
  };

  const handleStop = () => dispatch(action(playerAction.stop));
  const handlePlay = () => dispatch(action(playerAction.playPause));

  return (
    <div className={classes.root}>
      <Snackbar open={show} onClose={handleClose}>
        <Card className={classes.blue} square>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems={playing ? "center" : "flex-start"}
          >
            <Grid item xs>
              {playing && (
                <IconButton
                  aria-label="close"
                  size="large"
                  onClick={handleStop}
                >
                  <PauseIcon fontSize="inherit" />
                </IconButton>
              )}
            </Grid>
            <Grid item>
              <ReactPlayer
                url={url}
                height={playing ? playerheightPlaying : playerHeightStopped}
                width={playing ? playerWidthPlaying : playerWidthStopped}
                playing={playing}
                onPlay={handlePlay}
                onPause={handleStop}
                onEnded={handleStop}
                onProgress={setProgress}
                controls={false}
                lightPlayer
              />
            </Grid>
            <Grid item xs>
              <IconButton aria-label="close" size="large" onClick={handleClose}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Grid>
            {(progress.played > 0 || progress.loaded > 0) && (
              <Grid item xs={12} className={classes.blue}>
                <LinearProgress
                  variant="determinate"
                  value={progress.played * 100}
                />
              </Grid>
            )}
          </Grid>
        </Card>
      </Snackbar>
    </div>
  );
}
