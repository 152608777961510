import React from "react";
import firebase from "firebase";
import { includes } from "lodash";

const ADMINS = [
  "GGPHi1noW4atHAeZ49dBHH7tnD93",
  "7BsFjiiUTfh20sf7gCVuVTr7Bm12",
  "Wkg35zqDASTjJ6QtargkTeMWMz23",
  "ZKac4IEXdUYzAlAk4ncy1EDLbiw1"
];

const isAdmin = uid => includes(ADMINS, uid);

const logout = () => {
  firebase.auth().signOut();
};

// in order to make this hook update user done only once.
let updated = false;

export default function useCurrentUser(onLogin) {
  const [currentUser, setCurrentUser] = React.useState(
    firebase.auth().currentUser
  );

  React.useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      const db = firebase.firestore();

      setCurrentUser(
        user && {
          ...user,
          ...(user && { isAdmin: isAdmin(user.uid) })
        }
      );
      if (user && !updated) {
        console.log("update user");
        updated = true;
        db.collection("users")
          .doc(user.uid)
          .set({
            displayName: user.displayName,
            photoURL: user.photoURL
          });
      }
    });

    // Specify how to clean up after this effect:
    return unregisterAuthObserver;
  }, []);

  return [currentUser, logout];
}
