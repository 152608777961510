import * as firebase from "firebase/app";
import "firebase/firestore";

import shortid from "shortid";
import * as React from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";

import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { text } from "./static-text";

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

let storage = null;
const log = console.log;

export function ImageUpload({
  onRequestSave,
  onRequestClear,
  defaultFiles = []
}) {
  if (!storage) {
    storage = firebase.storage().ref();
  }
  const [files, setFiles] = React.useState(defaultFiles);
  const ref = React.useRef(null);

  return (
    <FilePond
      files={files}
      ref={ref}
      {...text.filePondLabels}
      allowMultiple={true}
      maxFiles={4}
      acceptedFileTypes={["image/*"]}
      maxFileSize="10MB"
      server={{
        process: (
          _fieldName,
          file,
          _metadata,
          load,
          error,
          progress,
          _abort
        ) => {
          const id = shortid.generate();

          const task = storage.child("images/" + id).put(file, {
            contentType: "image/jpeg"
          });

          task.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            snap => {
              log("progress: %o", snap);
              progress(true, snap.bytesTransferred, snap.totalBytes);
            },
            err => {
              log("error: %o", err);
              error(err.message);
            },
            () => {
              log("DONE");
              load(id);
              onRequestSave(id);
            }
          );
        },
        load: (source, load, error, progress, abort) => {
          progress(true, 0, 1024);

          storage
            .child("images/" + source)
            .getDownloadURL()
            .then(url => {
              let xhr = new XMLHttpRequest();
              xhr.responseType = "blob";
              xhr.onload = function(event) {
                let blob = xhr.response;
                log("loaded URL: %s", url);
                load(blob);
              };
              xhr.open("GET", url);
              xhr.send();
            })
            .catch(err => {
              error(err.message);
              abort();
            });
        }
      }}
      onupdatefiles={fileItems => {
        if (fileItems.length === 0) {
          onRequestClear();
        }
        setFiles(fileItems.map(fileItem => fileItem.file));
      }}
    />
  );
}
