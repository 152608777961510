import React from "react";

import { makeStyles, Typography, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    direction: "rtl",
    flip: "false",
    background: theme.palette.primary.dark,
    color: "#fff",
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(6),
    margin: -theme.spacing(4)
  },
  quote: {
    fontWeight: 700,
    letterSpacing: 3,
    lineHeight: 1.5,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14
    }
  }
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <Grid container justify="center" spacing={4} className={classes.root}>
      <Grid item md={8} lg={6} xs={11} container justify="center">
        <Typography
          component="p"
          variant="h6"
          className={classes.quote}
          style={{
            direction: "rtl"
          }}
        >
          ״וַאֲנִי בְּחַסְדְּךָ בָטַחְתִּי יָגֵל לִבִּי בִּישׁוּעָתֶךָ אָשִׁירָה
          לַיהוָה כִּי גָמַל עָלָי״ 
          <small style={{marginRight: 10, fontSize: 11}}>תהילים, יג׳ ו׳</small>
        </Typography>
      </Grid>
    </Grid>
  );
}
