import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  GridList,
  GridListTile,
  useMediaQuery,
  Typography,
  Container
} from "@material-ui/core";
import clsx from "clsx";
import { map, flatMap, isEmpty, compact, isEqual } from "lodash";

import HeroImage from "./HeroImage";
import mobileBackgroundImage from "./images/Michal-Hero-Mobile.jpg";
import backgroundImage from "./images/MichalHeader.jpg";
import { usePosts } from "./hooks";
import firebase from "firebase/app";
import "firebase/storage";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

let storage = null;

export default function AdvancedGridList() {
  const classes = useStyles();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const posts = usePosts({ limit: 200, filter: "photos" });

  const imageIds = compact(flatMap(posts, "imageIds"));

  if (!storage) {
    storage = firebase.storage();
  }

  const [images, setImages] = useState([]);

  useEffect(() => {
    Promise.all(
      map(imageIds, async imageId => {
        const imageRef = storage.ref(`/images/${imageId}`);
        const original = await imageRef.getDownloadURL();
        try {
          const thumbRef = storage.ref(`/images/thumbs/${imageId}_400x400`);
          const thumbnail = await thumbRef.getDownloadURL();

          return { original, thumbnail };
        } catch (e) {
          console.log(e);
          return { original, thumbnail: original };
        }
      })
    ).then(updatedImages => {
      !isEmpty(updatedImages) &&
        !isEqual(images, updatedImages) &&
        setImages(updatedImages);
    });
  }, [imageIds, images]);

  return (
    <React.Fragment>
      <Grid container>
        <HeroImage src={smUp ? backgroundImage : mobileBackgroundImage} />
      </Grid>
      <Container className={classes.albumHeader} maxWidth={false}>
        <Grid container spacing={3} className={classes.section}>
          <Grid item xs sm={3} md={2}></Grid>
          <Grid item xs={8} sm={3} md={2} justify="flex-start">
            <Typography component="h3" variant="h3">
              האלבום
            </Typography>
          </Grid>
          <Grid item xs={3} justify="flex-end"></Grid>
        </Grid>
      </Container>
      <Grid
        container
        className={clsx(classes.albumWrapper, isEmpty(images) && classes.empty)}
      >
        <GridList cellHeight={400} spacing={3} className={classes.gridList}>
          {map(images, (image, index) => (
            <GridListTile
              key={index}
              cols={smUp ? 1 : 1}
              rows={1}
              className={classes.gridListTile}
            >
              <Zoom
                zoomMargin={8}
                overlayBgColorEnd="rgba(0,0,0,0.95)"
                overlayBgColorStart="rgba(0,0,0,0)"
              >
                <img src={image.original} alt={"images" + index} height={300} />
              </Zoom>
            </GridListTile>
          ))}
        </GridList>
      </Grid>
    </React.Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  albumWrapper: {
    backgroundColor: theme.palette.secondary.dark,
    paddingBottom: 100
  },
  empty: {
    paddingBottom: 900
  },

  gridList: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
    background: theme.palette.secondary.dark,
  },
  gridListTile: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  titleBar: {
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
      "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
  },
  albumHeader: {
    background: theme.palette.secondary.dark,
    color: "white",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  icon: {
    color: "white"
  }
}));
