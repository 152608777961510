import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import CardMedia from "@material-ui/core/CardMedia";
import { formatDistanceToNow } from "date-fns";
import { he } from "date-fns/locale";
import { noop } from "lodash";
// import Ticker from 'react-ticker'
import { text } from "./static-text";

const useStyles = makeStyles(theme => ({
  card: {
    marginRight: theme.spacing(1),
    marginLeft: 1,
    marginBottom: theme.spacing(1),
    width: `calc(100% - ${theme.spacing(6)})`,
    background: "transparent",
    transition: "background 0.2s ease-in-out",
    "&:hover": {
      background: theme.palette.primary.dark
    },
    cursor: "pointer"
  },
  title: {
    height: 120,
    direction: "rtl",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    background: "rgba(0,0,0,0.3)",
    color: theme.palette.primary.contrastText,
    borderBottom: "0.5px solid rgba(255,255,255,0.1)"
  },
  footer: {
    flip: false,
    direction: "rtl",
    background: "rgba(255,255,255,0.2)",
    color: theme.palette.primary.contrastText
  },
  uploadedAt: {
    color: theme.palette.primary.contrastText
  },
  content: {
    flex: "1 0 auto"
  },

  avatar: {
    color: theme.palette.primary.dark,
    background: theme.palette.primary.light
  }
}));

export default function MediaCard({ onClick = { noop }, ...songDetails }) {
  const classes = useStyles();
  const theme = useTheme();
  const { url, imageUrl, title, owner, date } = songDetails;
  return (
    <Card
      className={classes.card}
      onClick={e => onClick(e, songDetails)}
      square
    >
      <CardHeader title={title} className={classes.title} />

      <CardMedia
        component={"img"}
        height={`calc((100% - ${theme.spacing(6)}) * 3 / 4)`}
        width={`calc(100% - ${theme.spacing(6)})`}
        image={imageUrl}
      ></CardMedia>

      <CardHeader
        className={classes.footer}
        avatar={
          <Avatar
            aria-label={owner.displaynName}
            src={owner.photoURL}
            className={classes.avatar}
          />
        }
        title={<strong>{owner.displayName}</strong>}
        subheader={displayCreatedAt(songDetails)}
        subheaderTypographyProps={{ className: classes.uploadedAt }}
      />
    </Card>
  );
}

const displayCreatedAt = post => {
  if (!post || !post.createdAt || !post.createdAt.seconds) {
    return "";
  }

  const createdAt = new Date(post.createdAt.seconds * 1000);

  return `${text.feed.publishedAt} ${formatDistanceToNow(createdAt, {
    locale: he
  })}`;
};
