import React from "react";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import {
  Avatar,
  useMediaQuery,
  makeStyles,
  useScrollTrigger,
  Fab
} from "@material-ui/core";

import { useTheme } from "@material-ui/core/styles";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import MoreIcon from "@material-ui/icons/MoreHoriz";
import CreateIcon from "@material-ui/icons/Create";
import CloseIcon from "@material-ui/icons/Close";
import ShareIcon from "@material-ui/icons/Share";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import { noop } from "lodash";
import { useQueryParam, BooleanParam } from "use-query-params";
import { useHistory, useLocation } from "react-router-dom";
import { text } from "./static-text";
import { SHOW_LOGIN_PARAM } from "./LoginDialog";
import { useCurrentUser } from "./hooks";

const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    bottom: 0,
    right: 0,
    transform: "translateZ(0px)",
    flexGrow: 1,
    zIndex: 2
  },
  fab: {
    background: theme.palette.primary.light,
  },
  speedDial: {
    fontSize: 16,
    
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      bottom: theme.spacing(1),
      right: theme.spacing(1)
    },
    [theme.breakpoints.up("md")]: {
      bottom: theme.spacing(2),
      right: theme.spacing(2)
    },
    [theme.breakpoints.up("lg")]: {
      bottom: theme.spacing(4),
      right: theme.spacing(4)
    }
  }
}));

export default function SpeedDialTooltipOpen() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [currentUser, logout] = useCurrentUser();
  console.log({ currentUser });
  const [loginOpen = false, setLoginOpen] = useQueryParam(
    SHOW_LOGIN_PARAM,
    BooleanParam
  );
  const trigger = useScrollTrigger();

  const theme = useTheme();
  const matchMobileWidth = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleActionAnd = (action = noop) => {
    handleClose();
    action();
  };

  if (!currentUser) {
    return (
      <div className={classes.root}>
        <Fab
          variant="extended"
          size="large"
          color="primary"
          aria-label={text.speedDial.login}
          className={classes.speedDial}
          onClick={() => setLoginOpen(true)}
        >
          <TouchAppIcon />
          {text.speedDial.login}
        </Fab>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <SpeedDial
        FabProps={{
          size: matchMobileWidth ? "small" : "large",
          color: "primary",
          className: classes.fab
        }}
        ariaLabel="תפריט משתמש"
        className={classes.speedDial}
        icon={
          <SpeedDialIcon
            style={{
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              display: "flex"
            }}
            icon={
              currentUser ? (
                <Avatar
                  style={{ width: "100%", height: "100%" }}
                  src={currentUser.photoURL}
                />
              ) : (
                <MoreIcon />
              )
            }
            openIcon={<CloseIcon />}
          />
        }
        hidden={matchMobileWidth && trigger}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction="up"
        color="red"
      >
        <SpeedDialAction
          key={text.speedDial.logout}
          icon={<ExitToAppIcon />}
          tooltipTitle={text.speedDial.logout}
          tooltipOpen
          onClick={() => handleActionAnd(logout)}
        />
      </SpeedDial>
    </div>
  );
}
