export const text = {
  more: "עוד",
  home: {
    songsTitle: "שירים למיכל",
    moreSongs: "לכל השירים",
    albumTitle: "האלבום",
    morePhotos: "לכל התמונות",
    feedTitle: "הפיד של מיכל",
    morePosts: "עוד"
  },
  songs: {
    lastSharesTitle: "שותפו לאחרונה",
    allSongsTitle: "כל השירים"
  },
  nav: {
    home: "עמוד הבית",
    feed: "הפיד של מיכל",
    songs: "שירים למיכל",
    album: "האלבום"
  },
  speedDial: {
    login: "התחבר",
    logout: "יציאה"
  },
  feed: {
    newPostTextPlaceholder: "שתפו מהלב...",
    send: "פרסם",
    publishedAt: "פורסם לפני",
    sharePhotosTitle: "הוספת תמונות",
    shareVideoTitle: "הוספת שיר",
    shareVideoSubtitlePrefix: "ניתן לשתף שירים מ-",
    shareVideoURLPlaceHolder: "לינק לשיר (...//:https)",
    publishThankYouTitle: "תודה רבה על השיתוף"
  },
  login: {
    preLoginTitle: "בחרו כיצד להתחבר",
    preLoginSubtitle: "כך תוכלו לכתוב ולשתף תמונות ושירים",
    // postLoginTitle: "תודה.",
    // postLoginSubstitle: "כעת ניתן לשתף בפיד של מיכל"
    joinDatePrefix: "פה מ"
  },
  filePondLabels: {
    labelIdle:
      'גררו את התמונות לפה או <span class="filepond--label-action"> בחרו תמונה מהמחשב/טלפון </span>',
    labelInvalidField: "ניתן להעלות רק תמונות",
    labelFileWaitingForSize: "בודק את גודל התמונה",
    labelFileSizeNotAvailable: "לא היה ניתן להעריך את גודל התמונה",
    labelFileLoading: "טוען",
    labelFileLoadError: "שגיאה בהעלאת התמונה",
    labelFileProcessing: "מעלה את התמונה",
    labelFileProcessingComplete: "התמונה נשמרה בהצלחה",
    labelFileProcessingAborted: "העלאת התמונה בוטלה",
    labelFileProcessingError: "שגיאה במהלך העלאת התמונה",
    labelFileRemoveError: "שגיאה בהסרת תמונה",
    labelTapToCancel: "בטל העלאה",
    labelTapToRetry: "נסה שנית",
    labelTapToUndo: "בטל",
    labelButtonRemoveItem: "הסר",
    labelButtonRetryItemLoad: "נסה שוב",
    labelButtonAbortItemProcessing: "בטל",
    labelButtonUndoItemProcessing: "בטל העלאה",
    labelButtonRetryItemProcessing: "נסה שוב",
    labelButtonProcessItem: "שמור תמונה",
    labelMaxFileSizeExceeded: "התמונה גדולה מדי",
    labelMaxTotalFileSizeExceeded: "התמונה גדולה מדי",
    labelMaxTotalFileSize: "גודל תמונה מקסימלי {filesize}",
    labelMaxFileSize: "גודל תמונה מקסימלי {filesize}"
  }
};
