import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  CircularProgress,
  useMediaQuery
} from "@material-ui/core";
import { map } from "lodash";
import HeroImage from "./HeroImage";
import PostCard from "./PostCard";
import PostCardNew from "./PostCardNew";
import backgroundImage from "./images/withAHat.jpg";
import mobileBackgroundImage from "./images/withAHat.jpg";
import { usePaginatedPosts } from "./hooks";

export default function Feed() {
  const classes = useStyles();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const [loading, posts, hasMore, loadMore] = usePaginatedPosts();

  return (
    <Grid
      container
      justify="center"
      direction="column"
      alignItems="center"
      spacing={0}
      className={classes.root}
    >
      <Grid>
        <HeroImage
          src={smUp ? backgroundImage : mobileBackgroundImage}
          title="הפיד של מיכל"
        />
      </Grid>
      <Grid
        item
        className={classes.feedContainer}
        md={12}
        xs={12}
        container
        direction="column"
        alignItems="center"
        spacing={3}
      >
        <Grid item md={12} xs={12}>
          <PostCardNew />
        </Grid>
        {map(posts, post => (
          <Grid
            key={post.id}
            item
            md={6}
            xs={12}
            style={{ display: "flex" }}
            container
          >
            <PostCard post={post} />
          </Grid>
        ))}
      </Grid>
      {loading && (
        <Grid item md={12} xs={12} className={classes.loaderWrapper}>
          <CircularProgress />
        </Grid>
      )}
      {!loading && hasMore && (
        <Grid item md={12} xs={12}>
          <Button onClick={() => console.log("Load More...")}>Load More</Button>
        </Grid>
      )}
    </Grid>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    background: "#efe8dc",
    paddingBottom: 100
  },
  feedContainer: {
    marginTop: -100,
    zIndex: 1
  },
  loaderWrapper: {
    marginTop: 100,
    maringBottom: 100
  }
}));
