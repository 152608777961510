import React from "react";
import firebase from "firebase";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { useCurrentUser } from "./hooks";
import {
  Grid,
  Button,
  Dialog,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  IconButton,
  Typography
} from "@material-ui/core";

import CardActions from "@material-ui/core/CardActions";

import CloseIcon from "@material-ui/icons/Close";
import HeartIcon from "@material-ui/icons/Favorite";

import { get } from "lodash";
import { format } from "date-fns";
import { he } from "date-fns/locale";

import { useQueryParam, BooleanParam } from "use-query-params";
import { makeStyles } from "@material-ui/core/styles";
import { text } from "./static-text";

export const SHOW_LOGIN_PARAM = "showLogin";

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false
  }
};

export default function LoginDialog(props) {
  const classes = useStyles();

  const [open = false, setOpen] = useQueryParam(SHOW_LOGIN_PARAM, BooleanParam);

  const [currentUser, logout] = useCurrentUser();

  const handleClose = () => {
    setOpen(false);
  };

  const joinDate = `${text.login.joinDatePrefix}${get(
    currentUser,
    "metadata.creationTime"
  ) &&
    format(new Date(currentUser.metadata.creationTime), "MMMM yyyy", {
      locale: he
    })}`;

  return [
    <Dialog onClose={handleClose} aria-labelledby="login-dialog" open={open}>
      <Card className={classes.card}>
        <CardHeader
          action={
            <IconButton aria-label="settings" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          }
        />

        <CardContent className={classes.cardContent}>
          {currentUser ? (
            <Grid
              spacing={3}
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <Typography variant="h3" color="primary" component="h3">
                  מחוברים
                </Typography>
              </Grid>
              <Grid item>
                <HeartIcon className={classes.heartIcon} />
              </Grid>
              <Grid item>
                <Avatar
                  aria-label="recipe"
                  className={classes.avatar}
                  src={get(currentUser, "photoURL")}
                />
              </Grid>
              <Grid
                item
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Typography variant="caption" component="h4">
                  {currentUser.displayName}
                </Typography>
                <Typography variant="caption" component="h4">
                  {joinDate}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid
              spacing={3}
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid
                item
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Typography variant="h4" color="textSecondary" component="h4">
                  {text.login.preLoginTitle}
                </Typography>

                <Typography variant="body1" color="textPrimary" component="p">
                  {text.login.preLoginSubtitle}
                </Typography>
              </Grid>
              <Grid
                item
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <HeartIcon className={classes.heartIcon} />
              </Grid>
              <Grid item>
                <StyledFirebaseAuth
                  uiConfig={uiConfig}
                  firebaseAuth={firebase.auth()}
                />
              </Grid>
            </Grid>
          )}
        </CardContent>
        {currentUser && (
          <CardActions disableSpacing>
            <Button aria-label="התנתקות" color="primary" onClick={logout}>
              התנתקות
            </Button>
          </CardActions>
        )}
      </Card>
    </Dialog>
  ];
}

const useStyles = makeStyles(theme => ({
  card: {
    direction: "rtl",
    minWidth: 280
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  heartIcon: {
    fontSize: 80,
    color: theme.palette.primary.light
  },
  cardContent: {
    padding: theme.spacing(4),
    direction: "rtl"
  },
  avatar: {
    backgroundColor: theme.palette.primary.light
  }
}));
