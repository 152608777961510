import React from "react";
import { Parallax } from "react-parallax";
import { makeStyles } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      height: 475
    },
    [theme.breakpoints.up("sm")]: {
      height: 475
    },
    [theme.breakpoints.up("md")]: {
      height: 600
    },
    [theme.breakpoints.up("lg")]: {
      height: 700
    },
    [theme.breakpoints.up("xl")]: {
      height: 900
    },
    width: "100vw",
    justifyContent: "center",
    alignItems: "center",
    display: "flex"
  }
}));

export default function HeroImage({ src, children }) {
  const classes = useStyles();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Parallax
      bgImage={src}
      bgImageAlt="Hero Image"
      strength={smUp ? (lgUp ? 550 : 300) : 280}
    >
      <div className={classes.root}>{children}</div>
    </Parallax>
  );
}
