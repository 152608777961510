import React from "react";
import Slider from "react-slick";
import { makeStyles, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import "slick-carousel";
import $ from "jquery";
import MediaCard from "./MediaCard";
import { map, noop } from "lodash";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { usePosts } from "./hooks";

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4)
    }
  }
}));

export default function MediaCarousel({
  onSelected = noop,
  limit = 20,
  fullpage
}) {
  const classes = useStyles();
  const posts = usePosts({ limit, filter: "songs" });
  
  React.useEffect(() => {
    if (posts.length > 2) {
      $("#media-crousel").slick(fullpage ? fullpageSettings : settings);
    }
  })

  return (
    <div className={classes.root}>
      <div id="media-crousel">
      {map(posts, post => (
          <MediaCard
            key={post.id}
            onClick={onSelected}
            {...{
              title: post.videoTitle,
              url: post.videoLink,
              imageUrl: post.videoThumb,
              owner: post.author,
              createdAt: post.createdAt
            }}
          />
        ))}
      </div>
    </div>
  );
}

const settings = {
  rtl: true,
  dots: true,
  speed: 1200,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  autoplaySpeed: 7000,
  infinite: false,
  autoplay: false,
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        
      }
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        
      }
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false
      }
    }
  ]
};

const fullpageSettings = {
  rtl: true,
  dots: true,
  infinite: false,
  speed: 1200,
  slidesToShow: 5,
  slidesToScroll: 5,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 4,
        dots: true
      }
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: true
      }
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2
      }
    },
    {
      breakpoint: 650,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};
